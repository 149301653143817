import React from 'react';
import CopyToClipboard from './CopyToClipboard';
import { CustomerInformation, InfoBlockType } from '../../../types';
import { getC4CURL } from '../utils';

const ContactInfo: React.FC<CustomerInformation> = ({
    firstName,
    middleName,
    lastName,
    dateOfBirth,
    houseNumber,
    street,
    city,
    postalCode,
    phoneNumber,
    email,
    accountNumber,
    ticketNumber,
    customerId,
}) => {
    const name = `${firstName} ${middleName ? `${middleName} ` : ''}${lastName}`;

    return (
        <div className="contact-info">
            <div className="info-block">
                <div className="data-block row column">
                    <span className="label">Name</span>
                    <span>{name}</span>
                </div>

                <div className="data-block row column">
                    <span className="label">Date of birth</span>
                    <span>{dateOfBirth}</span>
                </div>
            </div>

            <div className="info-block">
                <span className="label">Address</span>
                <span>
                    {houseNumber} {street}
                </span>
                <span>{city}</span>
                <span>{postalCode}</span>
            </div>

            <div className="info-block">
                <span className="label">Contact</span>
                <span>{phoneNumber}</span>
                <span>{email}</span>
            </div>

            <div className="info-block">
                <div className="data-block">
                    <span className="label">Account Number</span>
                    <div className="row middle ">
                        <span>{accountNumber ? accountNumber : 'Unavailable'}</span>
                        {/*TODO How are we handling null data across this whole component*/}
                        {accountNumber ? <CopyToClipboard data={accountNumber} /> : null}
                    </div>
                </div>

                <div className="data-block">
                    <span className="label">Ticket Number</span>
                    <div className="row middle">
                        <span>{ticketNumber ? ticketNumber : 'Unavailable'}</span>
                        {/*TODO How are we handling null data across this whole component*/}
                        {ticketNumber ? <CopyToClipboard data={ticketNumber} /> : null}
                    </div>
                </div>

                <div>
                    <a href={getC4CURL(customerId)} target="_blank" className="c4c">
                        View in C4C
                    </a>
                </div>
            </div>
        </div>
    );
};

const InfoBlock: React.FC<InfoBlockType> = props => {
    // Work in progress
    return <div className="info-block"></div>;
};

export default ContactInfo;
