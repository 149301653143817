import React, { useState, useEffect } from 'react';
import Tick from '../images/tick-fill.svg';

interface CopyToClipboardProps{
  data:string;
}

const CopyToClipboard:React.FC<CopyToClipboardProps> = ({data}) => {
  const [copiedData, setCopiedData] = useState(false);

  useEffect(()=>{
    if(copiedData) {
      setTimeout(() => {
        setCopiedData(false);
      }, 3000);
    }
  }, [copiedData])

  const copyToClipboard = (copiedText:string)=>{
    setCopiedData(true);
    navigator.clipboard.writeText(copiedText);
  }

  return (
  <button onClick={()=> copyToClipboard(data)} className="copy-button">
    {copiedData ? <img src={Tick} width="16px" height="16px" alt="Copy to clipboard" /> : <span />} 
  </button>
  )
}

export default CopyToClipboard

