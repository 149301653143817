const env = process.env.REACT_APP_ENV || 'dev';

const urls: { [key: string]: string } = {
    dev: 'https://wepw24esta.execute-api.eu-west-2.amazonaws.com/dev',
    uat: 'https://wa99cshhqe.execute-api.eu-west-2.amazonaws.com/uat',
    prod: 'https://qj72ca5lfi.execute-api.eu-west-2.amazonaws.com/prod',
};

export const BASE_URL = urls[env] || urls.dev;

export const getC4CURL = (customerId: string) => {
    const c4cUrls: { [key: string]: string } = {
        dev: `https://my319422-sso.crm.ondemand.com/sap/public/byd/runtime?bo_ns=http://sap.com/thingTypes&bo=COD_GENERIC&node=Root&operation=OnExtInspect&param.InternalID=${customerId}&param.Type=COD_SEOD_ACCOUNT_TT&sapbyd-agent=TAB&OBNRedirect=X`,
        uat: `https://my344766-sso.crm.ondemand.com/sap/public/byd/runtime?bo_ns=http://sap.com/thingTypes&bo=COD_GENERIC&node=Root&operation=OnExtInspect&param.InternalID=${customerId}&param.Type=COD_SEOD_ACCOUNT_TT&sapbyd-agent=TAB&OBNRedirect=X`,
        prod: `https://my342950-sso.crm.ondemand.com/sap/public/byd/runtime?bo_ns=http://sap.com/thingTypes&bo=COD_GENERIC&node=Root&operation=OnExtInspect&param.InternalID=${customerId}&param.Type=COD_SEOD_ACCOUNT_TT&sapbyd-agent=TAB&OBNRedirect=X`,
    };
    return c4cUrls[env] || c4cUrls.dev;
};