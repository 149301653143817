import React from 'react'
import Main from './components/Main';
import { PluginHarness } from '@missionlabs/smartagent-app-components';

const devContact = {
		ID: "2f874e2e-14df-4cb9-96c3-7377c57470ae"
}

const App : React.FC<{}> = () => {

    const isEmbedded = window.parent !== window;

    return (
        <>
            {isEmbedded
                ? <Main />
                : <PluginHarness contact={devContact}>
                      <Main />
                  </PluginHarness>}
        </>
    )
}

export default App
