import axios from 'axios';
import { useState, useEffect } from 'react';
import { CustomerInformation } from '../../../types';
import { BASE_URL } from '../utils';

const useGetCustomerInfo = (customerId: string, token: string) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<CustomerInformation | null>(null);
    const url = `${BASE_URL}/customer-details/${customerId}`;

    useEffect(() => {
        if (!customerId) return setData(null);
        getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId]);

    const getData = async () => {
        setLoading(true);
        try {
          const { data } = await axios({
              url,
              headers: {
                  'X-Amz-Security-Token': token,
              },
          });
          console.log(data)
            setData(data);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    return {
        loading,
        data,
    };
};

export default useGetCustomerInfo;
