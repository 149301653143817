import React, { useState, useEffect, useRef } from 'react';
import { BeatLoader } from 'react-spinners';
import SmartAgent from '@missionlabs/smartagent-app-components/dist/smartagent';
import { Box } from '@missionlabs/smartagent-app-components';
import '../../node_modules/@missionlabs/smartagent-app-components/dist/index.css';
import useGetCustomerInfo from '../hooks/useGetCustomerInfo';

import ContactInfo from './ContactInfo';

const Main: React.FC<any> = props => {
    const sm = useRef<SmartAgent>(props.sm || new SmartAgent());
    const [contactId, setContactId] = useState('');
    const [token, setToken] = useState('');

    const { loading, data } = useGetCustomerInfo(contactId, token);

    useEffect(() => {
        setup();
        // eslint-disable-next-line
    }, []);

    const setup = async () => {
        let contact;
        if (props.contact) {
            contact = props.contact;
            sm.current.init();
        } else {
            contact = await sm.current.init();
        }

        console.log('[tw-customer-information plugin] got contact', contact);
        setToken(await sm.current.getToken());
        setContactId(contact.ID);

        sm.current.on('contact_updated', async (contact: any) => {
            if (contact.ID) {
                setToken(await sm.current.getToken());
                setContactId(contact.ID);
            }
        });

        sm.current.showPlugin();
    };

    const renderInner = () => {
        console.log(data);
        return (
            <div className="plugin">
                {data ? (
                    <ContactInfo {...data} />
                ) : loading ? (
                    <div className="plugin-loading">
                        <BeatLoader />
                    </div>
                ) : (
                    <div className="plugin-error">
                        <span>No data available</span>
                    </div>
                )}
            </div>
        );
    };

    const isEmbedded = window.parent !== window;
    return isEmbedded ? (
        renderInner()
    ) : (
        <Box alt header="Customer details" collapse>
            {renderInner()}
        </Box>
    );
};

export default Main;
